<template>
  <v-container>
    <BasicCrud
      ref="crud"
      :fields="fields"
      :title="$t('Vouchers')"
      :new-title="$t('Create vouchers')"
      path="voucher"
      :customTableTemplates="customTableTemplates"
      customSaveEvent="save"
      customEditClickEvent="edit"
      :hideEditAction="!isMaster()"
      :hideDeleteAction="true"
      :showNewButton="false"
      @save="save"
      @edit="edit"
    ></BasicCrud>

    <DefaultModal
      :title="$t('Edit voucher')"
      ref="modal"
      v-on:submit="saveVoucher"
      :submitting="submitting"
      :submitButtonText="$t('Save')"
    >
      <template v-slot:content>
        <DynamicFormContent :fields="editFields" />
      </template>
    </DefaultModal>
  </v-container>
</template>

<script>

import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import BasicCrud from '@/components/BasicCrud'
import DefaultModal from '@/components/DefaultModal'
import DynamicFormContent from '@/components/DynamicFormContent'
import api from '@/services/api'
import AppActions from '@/store/app/actions-types'

export default {
  name: "Voucher",

  components: {
    BasicCrud,
    DefaultModal,
    DynamicFormContent
  },

  computed: {
    ...mapState({
      userData: state => state.app.userData,
      fields: state => state.voucher.fields
    }),
  },

  data() {
    return {
      customTableTemplates: [
        {name: 'item.status', callback: (item) => this.$t(item.status)},
        {name: 'item.student', callback: (item) => item.student ? item.student.fullname : null},
      ],
      editFields: [],
      submitting: false,
      loading: false
    }
  },

  methods: {
    ...mapActions('app', [
        AppActions.OPEN_APP_ERROR_MESSAGE,
        AppActions.OPEN_APP_SUCCESS_MESSAGE
      ]
    ),

    save(data) {
      api.create('voucher/bulk', {codes: data.code})
        .then((response) => {
          let message = this.$t('Success on save vouchers.')

          if (response.data.codes.length == 0) {
            message = this.$t('Unable to save vouchers.')
          }

          if (response.data.duplicated.length > 0) {
            message = message + ' ' +  this.$t('Duplicated vouchers:') + ' ' + response.data.duplicated.join(', ')
          }

          if (response.data.codes.length > 0) {
            this[AppActions.OPEN_APP_SUCCESS_MESSAGE](message)
            this.$refs.crud.reloadData()
          } else {
            this[AppActions.OPEN_APP_ERROR_MESSAGE](message)
          }

          this.$refs.crud.closeModal()
        })
    },

    edit() {
      let fields = []
      let selected    = this.$refs.crud.selected
      let fieldId     = this.fields.find(field => field.name == 'id')
      let fieldStatus = this.fields.find(field => field.name == 'status')

      fieldId.value     = selected.id
      fieldStatus.value = selected.status
      fieldStatus.hide  = false

      fields.push(fieldId)
      fields.push(fieldStatus)

      Vue.set(this, 'editFields', fields)

      this.$refs.modal.openModal()
    },

    saveVoucher() {
      this.submitting = true

      api.update(
        'voucher',
        {status: this.editFields.find(field => field.name == 'status').value},
        this.$refs.crud.selected.id
      ).then(() => {
        this.$refs.modal.closeModal()
        this.submitting = false
        this.$refs.crud.reloadData()
      })
      .catch(() => this.submitting = false)
    },

    isMaster() {
      return this.userData.roles.find(role => role == 'ROLE_MASTER') ? true : false
    }
  }
}

</script>
